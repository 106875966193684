'use strict'
import graphql from './graphql'
import { request, Settings } from 'http-lib'

let ActivitiesInstance = null

const domains = {
  mundipagg: '.mundipagg.com',
  pagarme: '.pagar.me'
}

export default class ActivitiesCore {
  constructor ({ environment = 'production', theme = 'mundipagg' }) {
    this.graphql = graphql.bind(this)()
    this.request = request
    this._env = environment
    this.theme = theme
    if (!ActivitiesInstance) {
      ActivitiesInstance = this
    }
    Settings.setup(theme, this.domain, environment)
    return ActivitiesInstance
  }

  static getInstance () {
    if (!ActivitiesInstance) {
      ActivitiesInstance = new ActivitiesCore({})
    }
    return ActivitiesInstance
  }

  get domain () {
    return domains[this.theme] || '.mundipagg.com'
  }

  get url () {
    let url
    switch (this._env) {
      case 'local':
      case 'development':
        url = 'https://devapi.mundipagg.com/activities/v2'
        break
      case 'staging':
        url = 'https://stgapi.mundipagg.com/activities/v2'
        break
      case 'contingency':
        url = 'https://ctgapi.mundipagg.com/activities/v2'
        break
      case 'production':
        url = 'https://api.mundipagg.com/activities/v2'
        break
      default:
        url = 'https://api.mundipagg.com/activities/v2'
    }
    return url
  }

  get envAcronym () {
    let acronym
    switch (this._env) {
      case 'local':
        acronym = 'local_'
        break
      case 'development':
        acronym = 'dev_'
        break
      case 'staging':
        acronym = 'stg_'
        break
      case 'contingency':
      case 'production':
        acronym = ''
        break
      default:
        acronym = ''
    }
    return acronym
  }

  paramSerializer (params = {}) {
    let queryParams = Object.entries(params)
    if (queryParams.length > 0) {
      queryParams = queryParams.reduce((prev, actual, index) => {
        return index === 0 ? prev + `${actual[0]}=${actual[1]}` : prev + `&${actual[0]}=${actual[1]}`
      }, '?')
    }
    return queryParams
  }
}

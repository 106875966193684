'use strict'

import {
  getActionLogs,
  getLastLog,
  getActions
} from './create.js'

/**
 * @namespace graphql
 */
export default function graphql () {
  return {
    getActionLogs: getActionLogs.bind(this),
    getLastLog: getLastLog.bind(this),
    getActions: getActions.bind(this)
  }
}

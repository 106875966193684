'use strict'

/**
 * @memberof graphql
 * @module getActionLogs
 * @function
 * @returns {Promise} Get Action Logs
 */
export function getActionLogs (merchantId, accountId, params = {}) {
  const fields = getFields(merchantId, accountId, params)
  const body = {
    query: `query ActivitiesQuery {logs (${fields}){items {  id,merchant_id,account_id,key,action,user_name,user_email,is_root,user_agent,ip,metadata,timestamp},total_items,total_page,after,before}}`
  }
  return this.request.post(`${this.url}/graphql`, body)
}

/**
 * @memberof graphql
 * @module getLastLog
 * @function
 * @returns {Promise} Get Action Logs
 */
export function getLastLog (entityId) {
  // eslint-disable-next-line
  let body = { query: `query ActivitiesQuery {last (key: \"${entityId}\", application: \"Dash\"){id,merchant_id,account_id,key,action,user_name,user_email,is_root,user_agent,ip,metadata,timestamp}}`}
  return this.request.post(`${this.url}/graphql`, body)
}

/**
 * @memberof graphql
 * @module getActions
 * @function
 * @returns {Promise} Actions
 */
export function getActions () {
  const body = {
    query: 'query ActivitiesQuery { actions { key, value } }'
  }
  return this.request.post(`${this.url}/graphql`, body)
}

function getFields (merchantId, accountId, params = {}) {
  const options = ['id', 'merchant_id', 'account_id', 'key', 'user_email', 'action', 'created_since', 'created_until', 'size', 'after', 'before']
  // eslint-disable-next-line
  let fields = [`merchant_id: \"${merchantId}\",account_id: \"${accountId}\", application: \"Dash\"`]
  for (const property in params) {
    if (options.includes(property)) {
      // eslint-disable-next-line
      typeof params[property] === 'string' ? fields.push(`${property}: \"${params[property]}\"`) : fields.push(`${property}: ${params[property]}`)
    }
  }
  return fields.toString()
}
